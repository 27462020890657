import React, { useState } from 'react'
import authorsBanar from "../../Assets/authorsBanar.jpg"
import authorsBanarMobile from "../../Assets/authorsMobileBanar.jpg"
import img from "../../Assets/authors01.png"
import post from '../../Assets/postsicon.svg'
import views from '../../Assets/viewsicon.svg'
import nextIcon from "../../Assets/authorNext.svg";
import prevIcon from "../../Assets/authorPrev.svg";
import { Link } from 'react-router-dom'
import Banner from './common/Banner'
const dummyData = [
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
    {
        name: "Angela Moss",
        designation: "Designation",
        posts: 300,
        views: "5m",
        image: img
    },
]

const Authors = () => {
    const [selectedPage, setSelectedPage] = useState(1);
    const handlePrevPage = () => {
        setSelectedPage(prev => (prev > 1 ? prev - 1 : 1)); // Prevent going below 1
    }

    const handleNextPage = () => {
        setSelectedPage(prev => (prev < 4 ? prev + 1 : 4)); // Prevent exceeding total pages
    }
    return (
        <div>
            <Banner
            title='Authors'
            content='A team of passionate cricket fans who love to express their ideas, views, perspectives, opinions and insights while analysing the game. Dynamic youngsters who relish this opportunity of writing for millions of readers.'
            largeBanner={authorsBanar}
            smallBanner={authorsBanarMobile}
            isSearchbar={true}
            />
            <div className='mx-1 md:mx-[10px] lg:mx-[10px] xl:mx-[140px] md:my-[80px] mt-1 mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px] px-[16px]'>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {
                        dummyData.map((obj, index) => (
                            <Link to={`/authors/${index}`}>
                                <div className={`flex p-6 gap-4 bg-white border rounded-[28px] ${index === 0 ? "" : "mt-4 md:mt-0"}`}>
                                    <div>
                                        <img src={obj.image} alt="" />
                                    </div>
                                    <div className='md:mt-2.5'>
                                        <div className='text-[#202020] text-[15px] md:text-[20px] leading-[15px] font-bold'>{obj.name}</div>
                                        <div className='text-[#6C6C6C] text-[12px] mt-[10px] md:flex gap-1 items-center'>
                                            <div className='flex gap-1 items-center'>
                                                {obj.designation} •
                                                <span><img src={post} alt="" /></span>
                                                {obj.posts} posts
                                            </div>
                                            <div className='flex gap-1 items-center'>
                                                <span className='hidden md:block'>•</span>
                                                <span><img src={views} alt="" /></span>
                                                {obj.views} Views
                                            </div>
                                        </div>
                                        <button className='border border-[#3A32D129] px-[25px] py-[12px] leading-3 text-[#3A32D1] rounded-full w-max text-[14px] font-semibold mt-[22px]'>VIEW POST</button>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                <div className='flex justify-between items-center mt-[80px] md:mt-[120px]'>
                    <div onClick={handlePrevPage} className='cursor-pointer'>
                        <img src={prevIcon} alt="prev" />
                    </div>
                    <div className='border bg-white p-2 rounded-2xl flex'>
                        {
                            [1, 2, 3, 4].map((num) => (
                                <div
                                    key={num}
                                    onClick={() => setSelectedPage(num)}
                                    className={`py-[12px] leading-4 px-[14px] cursor-pointer ${selectedPage === num ? 'bg-[#3A32D1] rounded-lg text-white' : 'bg-transparent text-black'}`}
                                >
                                    {num}
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={handleNextPage} className='cursor-pointer'>
                        <img src={nextIcon} alt="next" />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Authors
