export const getMobileDetect = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    return md;
}

export const SlotProps = {
    Desktop_Below_Match_Carousel_II: {
        style: {display:'inline-block', width: '728px', 'height':'90px'},
        'data-ad-client': "ca-pub-3220816308063959",
        'data-ad-slot': "6130152511"
    },
    Desktop_Below_Match_Carousel: { // Home page CMS Ads (2 Squares on both side sections)
        style: { "display": "block" },
        "data-ad-client": "ca-pub-3220816308063959",
        "data-ad-slot": "9638054620",
        "data-ad-format": "auto",
        "data-full-width-responsive": "true",
    },
    Mobile_Below_Match_Carousel: { // only for mobile, Schedule, newsDetail  > Component, newsV2,  fantasyV2, pages > series
        style: { display: 'inline-block', width: '320px', height: '100px' },
        'data-ad-client': "ca-pub-3220816308063959",
        'data-ad-slot': "4465387484",
    },
    News_Details_Page_Square: { // desktop -> top right side (in side section), in mobile -> below Thank You message
        style: { display:"inline-block",width:"336px",height:"280px"},
        'data-ad-client': "ca-pub-3220816308063959",
        'data-ad-slot':"6268154828",
    },
    Mobile_Above_Footer_Nav: {
        style: { display: 'inline-block', width: '320px', height: "50px" },
        "data-ad-client": "ca-pub-3220816308063959",
        'data-ad-slot': "8313795861"
    },
    Desktop_Asides_1: { // Desktop - left side under rankings - right side above current series & and inside article - Above & below Exclusive videos
        style: {display:" block"},
        'data-ad-client': "ca-pub-3220816308063959",
        'data-ad-slot': "4242355775",
        'data-ad-format': "auto",
        'data-full-width-responsive': "true"
    },
    Desktop_Asides_2: { // Desktop - left side under rankings - right side above current series and inside article - Above & below Exclusive videos
        style: {display:'inline-block', width: '300px', height:'600px'},
        'data-ad-client' : "ca-pub-3220816308063959",
        'data-ad-slot': "6084122306"
    },
    Desktop_Above_Article: {
        style: {display:'inline-block', width:'980px', height:'120px'},
        'data-ad-client':"ca-pub-3220816308063959",
        'data-ad-slot':"6163077572"
    }
}

/*
Desktop -- Article top ad should be copied to home page.

DEsktop ma left ma last ma je ads che e sticky kari devani
*/