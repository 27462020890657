import React, { useEffect, useMemo } from 'react'


export const pushGoogleAd = (slotProps) => {
    try {
        if (typeof window === 'object' && window.location.host === "cricketgully.com") {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            console.log('done useNativeAdSlot push...');
        }
    }
    catch (_a) {
        console.log('Error while ins for useNativeAdSlot ', _a, slotProps);
    }
}

// https://medium.com/@deuos/how-to-implement-google-adsense-into-reactjs-803a7d0d3b8d 

let GoogleAdUnit = ({ slotProps }) => {
    useEffect(() => {
        pushGoogleAd(slotProps);
    }, [slotProps]);

    const dataPropsStr = useMemo(() => {
        try {
            return getPropsStr(slotProps);
        } catch (error) {
            return '-';
        }
    }, [slotProps]);
    return (
        <ins data-cgully-props={dataPropsStr} className="adsbygoogle" {...slotProps} style={{ ...(slotProps.style || {}) }}></ins>
    )

}

GoogleAdUnit = React.memo(GoogleAdUnit);

export default GoogleAdUnit;

function getPropsStr(props, del = " | ") {
    const strings = [];
    try {
        for (const key in props) {
            if (key === "style") {
                strings.push('style:' + getPropsStr(props.style, ";"))
            } else {
                strings.push(key + ':' + props[key]);
            }
        }
    } catch (error) {
        console.log('error while calculating getPropsStr ', error);
    }
    return strings.join(del);
}